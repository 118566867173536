










import { Component, Vue } from 'vue-property-decorator'

import UserProfileForm from '@/partials/forms/UserProfileForm.vue'

@Component({
  components: {
    UserProfileForm
  }
})
export default class InviteCompleteCreate extends Vue {

}
